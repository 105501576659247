
@import "./../scss/required";

.brand-logo {
    > svg {
        width: 125px;
        height: 30px;
        margin-top: -6px;
    }
    @include media-breakpoint-down(md) {
        display: block;
        width: 24px;
        overflow: hidden;
    }
}

